<template>
  <div class="page-container">
    <div class="header" style="padding: 0;">
      <page-header @navBack="$router.go(-1)" pageTitle="支付"></page-header>
    </div>
    <div class="main">
      <p style="padding: 10px 15px; background-color:#f5f5f5; margin:0;">请选择支付方式：</p>
      <div class="pay_list">
        <radio-group v-model="value">
          <div class="item" @click="value = '1'">
            <div class="info">
              <img src="@/assets/svg/wx.svg" alt="">
              <span>微信支付</span>
            </div>
            <radio checked-color="#FF8506" name="1" />
          </div>
          <div class="item" @click="value = '2'">
            <div class="info">
              <img src="@/assets/svg/zfb.svg" alt="">
              <span>支付宝支付</span>
            </div>
            <radio checked-color="#FF8506" name="2" />
          </div>
        </radio-group>
      </div>
    </div>
    <submit-bar
      :loading="loading"
      style="border-top: 1px solid #ddd;"
      :price="3000"
      button-text="立即支付"
      @submit="clickPay"
      button-color="#FF8506"
    />
  </div>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import { RadioGroup, Radio, SubmitBar, Dialog } from 'vant';
export default {
  name: "pay",
  components: {
    PageHeader, RadioGroup, Radio, SubmitBar
  },
  data: () => ({
    value: '1',
    loading: false
  }),

  methods: {
    clickPay () {
      this.loading = true
      setTimeout(() => {
        Dialog.alert({ title: '温馨提示', message: '网络超时，请重试', confirmButtonColor: '#FF8506' })
      }, 10000)
    }
  }
}
</script>

<style scoped>
.pay_list .item {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.pay_list .item img,
.pay_list .item span {
  display: inline-block;
  vertical-align: middle;
}
.pay_list .item img {
  width: 40px;
}
.pay_list .item span{ font-size: 16px; margin-left: 10px; }
</style>
